import { forwardRef, useCallback, useState } from 'react';

import FormInput, { type FormInputProps } from '../FormInput';

export interface PasswordFormInputProps extends FormInputProps {
  icon?: string;
}

const PasswordFormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (props, ref) => {
    const [type, setType] = useState<'password' | 'text'>('password');
    const [icon, setIcon] = useState<'eye' | 'crossed-eye'>('crossed-eye');

    const handleIconClick = useCallback(() => {
      setType((prev) => {
        if (prev === 'password') {
          setIcon('eye');
          return 'text';
        } else {
          setIcon('crossed-eye');
          return 'password';
        }
      });
    }, []);

    return (
      <FormInput
        {...props}
        ref={ref}
        type={type}
        endIcon={icon}
        onEndIconClick={handleIconClick}
      />
    );
  }
);

PasswordFormInput.displayName = 'PasswordFormInput';

export default PasswordFormInput;
