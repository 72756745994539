import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  VFC,
} from 'react';
import { useForm } from 'react-hook-form';
import FormInput from 'shared/src/components/FormInput';
import Button from 'shared/src/uikit/Button';
import PopupLayout from 'shared/src/components/PopupLayout';
import Icon from 'shared/src/uikit/Icon';
import { yupResolver } from '@hookform/resolvers/yup';

import styles from './RequiredEmailCodeScreen.module.css';
import { requiredEmailCodeSchema } from './RequiredEmailCode.schema';

const inputsIndexes = [0, 1, 2, 3];

type FormValues = {
  emailDigit: string[];
};

interface RequiredEmailCodePopupProps {
  isSubmitting: boolean;
  onSubmit: (emailCode: string) => Promise<unknown>;
  error?: string;
  onClearError: () => unknown;
  email?: string;
  onClose?: () => unknown;
}

const RequiredEmailCodePopup: VFC<RequiredEmailCodePopupProps> = ({
  isSubmitting,
  onSubmit,
  error,
  onClearError,
  email,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    handleSubmit,
    register,
    formState: { isDirty, isValid },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(requiredEmailCodeSchema),
  });

  const submit = useCallback(
    (values: FormValues) => {
      onSubmit(
        `${values.emailDigit[0]}${values.emailDigit[1]}${values.emailDigit[2]}${values.emailDigit[3]}`
      );
    },
    [onSubmit]
  );

  const isSetFullCode = useCallback(
    (event) => {
      const v = event.clipboardData.getData('Text').trim();
      if (v.length === 4) {
        if (window.getSelection) {
          window.getSelection()?.removeAllRanges();
        }
        setValue(`emailDigit.0`, v[0], { shouldDirty: true });
        setValue(`emailDigit.1`, v[1], { shouldDirty: true });
        setValue(`emailDigit.2`, v[2], { shouldDirty: true });
        setValue(`emailDigit.3`, v[3], { shouldDirty: true });
      }
      onClearError();
    },
    [onClearError, setValue]
  );

  const handleCycle = useCallback(
    ({
      curIndex,
      value,
      forward,
    }: {
      curIndex: number;
      value: string;
      forward: boolean;
    }) => {
      let nextIndex = -1;
      if (forward) {
        if (value) {
          switch (curIndex) {
            case 0:
            case 1:
            case 2:
              nextIndex = curIndex + 1;
              break;
            case 3:
              nextIndex = 0;
              break;
          }
        }
      } else if (forward === false) {
        if (!value) {
          switch (curIndex) {
            case 1:
            case 2:
            case 3:
              nextIndex = curIndex - 1;
              break;
            case 0:
              nextIndex = inputsIndexes.length - 1;
              break;
          }
        }
      }
      if (nextIndex > -1) {
        const next: HTMLInputElement | null | undefined =
          ref.current?.children[nextIndex]?.querySelector('input');
        if (next) {
          if (next.value) {
            next.select();
          }
          next.focus();
        }
      }
    },
    []
  );

  const handleEmailDigitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, dataset } = event.target;
      onClearError();
      handleCycle({ curIndex: Number(dataset.index), value, forward: true });
    },
    [onClearError, handleCycle]
  );

  const handleEmailDigitKeydown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Backspace') {
        const { value, dataset } = event.target as HTMLInputElement;
        handleCycle({ curIndex: Number(dataset.index), value, forward: false });
      }
    },
    [handleCycle]
  );

  const errorWithLineBreaks = useMemo(() => {
    if (error) {
      return error.replaceAll('. ', '.\n ');
    }
  }, [error]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className={styles.root}
      data-testid="demo-required-email-code-form"
    >
      {onClose && (
        <button type="button" className={styles.close} onClick={onClose}>
          <Icon className={styles.closeIcon} name="close" />
        </button>
      )}
      <div className={styles.title}>Enter Code</div>
      <div className={styles.description}>
        Enter the code sent to your email
        <br />“{email}”
      </div>
      <div className={styles.line} ref={ref}>
        {inputsIndexes.map((index) => (
          <FormInput
            key={index}
            data-testid={`demo-required-email-code-${index + 1}-form-input`}
            data-index={index}
            className={styles.input}
            inputClassName={styles.input}
            autoFocus={index === 0}
            {...register(`emailDigit.${index}`, {
              onChange: handleEmailDigitChange,
            })}
            onPaste={isSetFullCode}
            onKeyDown={handleEmailDigitKeydown}
            maxLength={1}
            disabled={isSubmitting}
            errorClassName={styles.error}
          />
        ))}
      </div>
      {errorWithLineBreaks && (
        <span
          className={styles.error}
          data-testid="demo-required-email-code-form-input-error"
        >
          {errorWithLineBreaks}
        </span>
      )}
      <Button
        data-testid="demo-required-email-code-form-submit"
        type="submit"
        variant="dark-blue"
        className={styles.submitButton}
        disabled={!isDirty || !isValid || isSubmitting}
      >
        Submit
      </Button>
    </form>
  );
};

type RequiredEmailCodeScreenProps = RequiredEmailCodePopupProps;

const RequiredEmailCodeScreen: VFC<RequiredEmailCodeScreenProps> = (props) => {
  return (
    <PopupLayout hasClose={false}>
      <RequiredEmailCodePopup {...props} />
    </PopupLayout>
  );
};

export default RequiredEmailCodeScreen;
