import axios from 'axios';
import { stringify } from 'qs';
import * as Sentry from '@sentry/nextjs';
import { HTTP_STATUS_CODES } from 'shared/src/features/http/http.constants';

import { ProjectErrorCode } from 'container/PublicDemoPage/PublicDemoPage.constants';

export const apiInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'brackets' }),
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      const errorCode = error.response.data?.error?.code;

      if (
        statusCode >= HTTP_STATUS_CODES.HTTP_400_BAD_REQUEST &&
        statusCode < HTTP_STATUS_CODES.HTTP_500_INTERNAL_SERVER_ERROR &&
        errorCode !== ProjectErrorCode.PROJECT_EXPIRED &&
        errorCode !== ProjectErrorCode.PROJECT_PROTECTED &&
        errorCode !== HTTP_STATUS_CODES.HTTP_404_NOT_FOUND
      ) {
        Sentry.captureException(error, {
          contexts: {
            response: error.response,
          },
          tags: {
            statusCode,
            apiError: true,
            clientError: true,
          },
        });
      }
    }
    return Promise.reject(error);
  }
);
