import { useCallback, useMemo, useState, VFC } from 'react';
import FormInput from 'shared/src/components/FormInput';
import Button from 'shared/src/uikit/Button';
import PopupLayout from 'shared/src/components/PopupLayout';

import styles from './RequiredEmailScreen.module.css';

interface RequiredEmailPopupProps {
  isSubmitting: boolean;
  onSubmit: (email: string) => Promise<unknown>;
  error?: string;
  onClearError: () => unknown;
}

const RequiredEmailPopup: VFC<RequiredEmailPopupProps> = ({
  isSubmitting,
  onSubmit,
  error,
  onClearError,
}) => {
  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(email);
    },
    [onSubmit, email]
  );

  const handleEmailChange = useCallback(
    (event) => {
      setEmail(event.target.value);
      onClearError();
    },
    [onClearError]
  );

  const errorWithLineBreaks = useMemo(() => {
    if (error) {
      return error.replaceAll('. ', '.\n ');
    }
  }, [error]);

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.root}
      data-testid="demo-required-email-form"
    >
      <div className={styles.title}>Enter Your Email</div>
      <div className={styles.description}>
        Enter your email address to view the demo.
      </div>
      <FormInput
        data-testid="demo-required-email-form-input"
        dataErrorTestId="demo-required-email-form-input-error"
        autoFocus
        type="email"
        value={email}
        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
        onChange={handleEmailChange}
        error={errorWithLineBreaks}
        disabled={isSubmitting}
        errorClassName={styles.error}
      />
      <Button
        data-testid="demo-required-email-form-submit"
        type="submit"
        variant="purple"
        className={styles.submitButton}
        disabled={!email || isSubmitting}
      >
        Submit
      </Button>
    </form>
  );
};

type RequiredEmailScreenProps = RequiredEmailPopupProps;

const RequiredEmailScreen: VFC<RequiredEmailScreenProps> = (props) => {
  return (
    <PopupLayout hasClose={false} className={styles.popup}>
      <RequiredEmailPopup {...props} />
    </PopupLayout>
  );
};

export default RequiredEmailScreen;
