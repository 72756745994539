import { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import ResponsiveRootStyle from 'shared/src/components/ResponsiveRootStyle';
import { isApiErrorResponse } from 'shared/src/api/utils/isApiError';
import axios from 'axios';

import {
  getSharedProject,
  GetSharedProjectResponse,
} from 'container/PublicDemoPage/api/project.api';
import DemoNotFound from 'container/PublicDemoPage/DemoNotFound';
import { getAbsoluteUrl } from 'utils/getAbsoluteUrl';
import {
  PASSCODE_STORAGE_PREFIX,
  REQUIRED_EMAIL_CODE_STORAGE_PREFIX,
  REQUIRED_EMAIL_STORAGE_PREFIX,
} from 'container/PublicDemoPage/PublicDemoPage.machine';
import { ProjectErrorCode } from 'container/PublicDemoPage/PublicDemoPage.constants';
import {
  getCookiesEmailCodeRequired,
  getCookiesEmailRequired,
  getCookiesPasscode,
  setCookiesEmailCodeRequired,
  setCookiesEmailRequired,
  setCookiesPasscode,
  removeCookiesEmailRequired,
} from 'container/PublicDemoPage/PublicDemoPage.utils';
import HeadCustomFont from 'components/HeadCustomFont';
import { isValidId } from 'utils/isValidId';
import { getIpAddress } from 'utils/getUserIpAddress';
import PassCodeScreen from 'container/PublicDemoPage/PasscodeScreen';
import RequiredEmailScreen from 'container/PublicDemoPage/RequiredEmailScreen';
import RequiredEmailCodeScreen from 'container/PublicDemoPage/RequiredEmailCodeScreen';

const PublicDemoPage = dynamic(() => import('container/PublicDemoPage'), {
  ssr: true,
});

interface DemoProps {
  demoId: string;
  ssEmail?: string;
  ssResponseDataStr?: string;
  ssResponseErrorCode?: string;
  ssResponseErrorDescription?: string;
  baseUrl: string;
}

type APIErrorParsed = {
  isPasswordProtected: boolean;
  isPasswordExpired: boolean;
  isEmailProtected: boolean;
  isEmailConfirmationRequired: boolean;
  errorDescription: string;
};

type SharedResponse = {
  data: GetSharedProjectResponse;
};

function parseAPIResponseError({
  response,
  code,
  description = '',
}: {
  response?: unknown;
  code?: string;
  description?: string;
}): APIErrorParsed {
  const parsed: APIErrorParsed = {
    isPasswordProtected: false,
    isPasswordExpired: false,
    isEmailProtected: false,
    isEmailConfirmationRequired: false,
    errorDescription: description,
  };
  if (response && isApiErrorResponse(response) && response.error?.code) {
    code = response.error.code;
    description = response.error.title;
    switch (code) {
      case ProjectErrorCode.PROJECT_PROTECTED:
        parsed.errorDescription = description;
        break;
      case ProjectErrorCode.PROJECT_EXPIRED:
        parsed.errorDescription = description;
        break;
      case ProjectErrorCode.PROJECT_LINK_EMAIL_REQUIRED:
        parsed.errorDescription = description;
        break;
      case ProjectErrorCode.PROJECT_LINK_EMAIL_CONFIRMATION_REQUIRED:
        parsed.errorDescription = description;
        break;
    }
  }
  switch (code) {
    case ProjectErrorCode.PROJECT_PROTECTED:
      parsed.isPasswordProtected = true;
      break;
    case ProjectErrorCode.PROJECT_EXPIRED:
      parsed.isPasswordExpired = true;
      break;
    case ProjectErrorCode.PROJECT_LINK_EMAIL_REQUIRED:
      parsed.isEmailProtected = true;
      break;
    case ProjectErrorCode.PROJECT_LINK_EMAIL_CONFIRMATION_REQUIRED:
      parsed.isEmailConfirmationRequired = true;
      break;
  }
  return parsed;
}

type SubmitParams = {
  hashedPassword?: string;
  email?: string;
  emailCode?: string;
};

const Demo = ({
  demoId,
  ssEmail,
  ssResponseDataStr,
  ssResponseErrorCode,
  ssResponseErrorDescription,
  baseUrl,
}: DemoProps) => {
  const ssResponseData: SharedResponse | null =
    typeof ssResponseDataStr === 'string'
      ? JSON.parse(ssResponseDataStr)
      : null;

  const [sharedResponse, setSharedResponse] = useState<SharedResponse | null>(
    ssResponseData
  );

  const [apiError, setApiError] = useState<APIErrorParsed>(
    parseAPIResponseError({
      code: ssResponseErrorCode,
      // do not send any error description from server
      // only code for flow required
      description: ssResponseErrorDescription ? '' : '',
    })
  );
  const {
    isPasswordProtected,
    isPasswordExpired,
    isEmailProtected,
    isEmailConfirmationRequired,
    errorDescription,
  } = apiError;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async ({
      hashedPassword,
      email,
      emailCode,
    }: SubmitParams): Promise<APIErrorParsed | undefined> => {
      try {
        setIsSubmitting(true);

        const response = await getSharedProject({
          demoId,
          password: hashedPassword,
          requiredEmail: email,
          requiredEmailCode: emailCode,
          origin: baseUrl, // TODO: set to https://app.storylane.io to debug production
        });

        setSharedResponse({
          data: response.data,
        });

        setApiError(parseAPIResponseError({}));
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);

        if (
          axios.isAxiosError(error) &&
          isApiErrorResponse(error.response?.data)
        ) {
          const apiError = parseAPIResponseError({
            response: error.response?.data,
          });
          return apiError;
        } else {
          console.error(error);
        }
      }
    },
    [baseUrl, demoId]
  );

  const handlePasswordSubmit = useCallback(
    async (password: string) => {
      const hashedPassword = btoa(password);
      const email = getCookiesEmailRequired(demoId);
      const emailCode = getCookiesEmailCodeRequired(demoId);
      const apiError = await handleSubmit({ hashedPassword, email, emailCode });
      if (!apiError || apiError.isEmailProtected) {
        // password is correct but email is required
        setCookiesPasscode(demoId, hashedPassword);
      }
      if (apiError) {
        if (apiError.isEmailProtected) {
          apiError.errorDescription = ''; // proceed to the next step without error
        }
        setApiError(apiError);
      }
    },
    [demoId, handleSubmit]
  );

  const handleEmailSubmit = useCallback(
    async (email: string) => {
      const hashedPassword = getCookiesPasscode(demoId);
      const apiError = await handleSubmit({ hashedPassword, email });
      if (!apiError || apiError.isEmailConfirmationRequired) {
        // email is correct but email code is required
        // what if user entered valid email domain but wrong email ? how to reset email
        setCookiesEmailRequired(demoId, email);
      }
      if (apiError) {
        if (apiError.isEmailConfirmationRequired) {
          apiError.errorDescription = ''; // proceed to the next step without error
        }
        setApiError(apiError);
      }
    },
    [demoId, handleSubmit]
  );

  const handleEmailCodeSubmit = useCallback(
    async (emailCode: string) => {
      const hashedPassword = getCookiesPasscode(demoId);
      const email = getCookiesEmailRequired(demoId);
      const apiError = await handleSubmit({ hashedPassword, email, emailCode });
      if (!apiError) {
        setCookiesEmailCodeRequired(demoId, emailCode);
      }
      if (apiError) {
        setApiError(apiError);
      }
    },
    [demoId, handleSubmit]
  );

  const handleClearError = useCallback(() => {
    setApiError({ ...apiError, errorDescription: '' });
  }, [apiError, setApiError]);

  const handleClearEmail = useCallback(() => {
    removeCookiesEmailRequired(demoId);
    globalThis.location.reload();
  }, [demoId]);

  if (isPasswordProtected || isEmailProtected || isEmailConfirmationRequired) {
    let screen = null;
    if (isPasswordProtected) {
      screen = (
        <PassCodeScreen
          isSubmitting={isSubmitting}
          onSubmit={handlePasswordSubmit}
          error={errorDescription}
          onClearError={handleClearError}
        />
      );
    } else if (isEmailProtected) {
      screen = (
        <RequiredEmailScreen
          isSubmitting={isSubmitting}
          onSubmit={handleEmailSubmit}
          error={errorDescription}
          onClearError={handleClearError}
        />
      );
    } else if (isEmailConfirmationRequired) {
      screen = (
        <RequiredEmailCodeScreen
          isSubmitting={isSubmitting}
          onSubmit={handleEmailCodeSubmit}
          error={errorDescription}
          onClearError={handleClearError}
          email={getCookiesEmailRequired(demoId) || ssEmail}
          onClose={handleClearEmail}
        />
      );
    }
    return (
      <>
        <Head>
          {ResponsiveRootStyle({
            isResponsiveDemoPlayerEnabled: false,
          })}
        </Head>
        <HeadCustomFont font={null} />
        {screen}
      </>
    );
  }

  if (isPasswordExpired) return <DemoNotFound subject="link" />;

  if (sharedResponse) {
    return (
      <PublicDemoPage
        baseUrl={baseUrl}
        responseData={sharedResponse.data}
        demoId={demoId}
      />
    );
  }

  // if there is any other error
  return <DemoNotFound subject="link" />;
};

export const getServerSideProps: GetServerSideProps<DemoProps> = async (
  context
) => {
  const demoId = Array.isArray(context.query.id)
    ? context.query.id[0]
    : context.query.id ?? '';

  const userAgent = context.req
    ? context.req.headers['user-agent']
    : navigator.userAgent;

  const userIP = getIpAddress(context.req);

  const absoluteUrl = getAbsoluteUrl(context.req);

  if (!isValidId(demoId)) {
    return {
      notFound: true,
      props: {
        ssResponseStr: null,
        baseUrl: absoluteUrl.origin,
        meta: {
          userAgent,
        },
      },
    };
  }

  const additionalQuery = Array.isArray(context.query.additionalQuery)
    ? context.query.additionalQuery[0]
    : context.query.additionalQuery ?? '';

  const hashedPassword =
    context.req.cookies?.[`${PASSCODE_STORAGE_PREFIX}${demoId}`];

  const hashedEmail =
    context.req.cookies?.[`${REQUIRED_EMAIL_STORAGE_PREFIX}${demoId}`];

  const hashedEmailCode =
    context.req.cookies?.[`${REQUIRED_EMAIL_CODE_STORAGE_PREFIX}${demoId}`];

  const passcode = hashedPassword ? decodeURIComponent(hashedPassword) : '';

  const email = hashedEmail ? decodeURIComponent(hashedEmail) : '';

  const emailCode = hashedEmailCode ? decodeURIComponent(hashedEmailCode) : '';

  try {
    const response = await getSharedProject({
      demoId,
      password: passcode,
      requiredEmail: email,
      requiredEmailCode: emailCode,
      additionalQuery,
      userIP,
      userAgent,
      origin: absoluteUrl.origin, // TODO: set to https://app.storylane.io to debug production
    });

    return {
      props: {
        demoId,
        ssResponseDataStr: JSON.stringify({
          data: response.data,
        }),
        baseUrl: absoluteUrl.origin,
        meta: {
          userAgent,
        },
      },
    };
  } catch (error: any) {
    const errorResponse = error.response;
    if (errorResponse) {
      return {
        props: {
          demoId,
          ssEmail: email,
          ssResponseErrorCode: String(errorResponse.data?.error?.code),
          ssResponseErrorDescription: String(
            errorResponse.data?.error?.description
          ),
          baseUrl: absoluteUrl.origin,
          meta: {
            userAgent,
          },
        },
      };
    }
    return {
      props: {
        demoId,
        baseUrl: absoluteUrl.origin,
        meta: {
          userAgent,
        },
      },
    };
  }
};

export default Demo;
