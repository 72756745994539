import { useCallback, useState, VFC } from 'react';
import Button from 'shared/src/uikit/Button';
import PopupLayout from 'shared/src/components/PopupLayout';
import PasswordFormInput from 'shared/src/components/PasswordFormInput';

import styles from './PasscodeScreen.module.css';

interface PasscodePopupProps {
  isSubmitting: boolean;
  onSubmit: (passcode: string) => Promise<unknown>;
  error?: string;
  onClearError: () => unknown;
}

const PasscodePopup: VFC<PasscodePopupProps> = ({
  isSubmitting,
  onSubmit,
  error,
  onClearError,
}) => {
  const [passcode, setPasscode] = useState('');

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(passcode);
    },
    [onSubmit, passcode]
  );

  const handlePasscodeChange = useCallback(
    (event) => {
      setPasscode(event.target.value);
      onClearError();
    },
    [onClearError]
  );

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.root}
      data-testid="demo-passcode-form"
    >
      <div className={styles.title}>Enter Passcode</div>
      <div className={styles.description}>
        Please enter the passcode below to view the demo
      </div>
      <PasswordFormInput
        data-testid="demo-passcode-form-input"
        dataErrorTestId="demo-passcode-form-input-error"
        placeholder="••••••"
        value={passcode}
        onChange={handlePasscodeChange}
        error={error}
        disabled={isSubmitting}
      />
      <Button
        data-testid="demo-passcode-form-submit"
        type="submit"
        variant="purple"
        className={styles.submitButton}
        disabled={!passcode || isSubmitting}
      >
        Submit
      </Button>
    </form>
  );
};

type PasscodeScreenProps = PasscodePopupProps;

const PasscodeScreen: VFC<PasscodeScreenProps> = (props) => {
  return (
    <PopupLayout hasClose={false}>
      <PasscodePopup {...props} />
    </PopupLayout>
  );
};

export default PasscodeScreen;
